/* You can add global styles to this file, and also import other style files */

html, body {margin: 0; padding: 0; height: 100vh;}
body { font-family: Roboto, "Helvetica Neue", sans-serif; }
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
